import img_1 from './../assets/gallery/img-1.jpeg'
import img_2 from './../assets/gallery/img-2.jpeg'
import img_3 from './../assets/gallery/img-3.jpeg'
import img_4 from './../assets/gallery/img-4.jpeg'
import img_5 from './../assets/gallery/img-5.jpeg'
import img_6 from './../assets/gallery/img-6.jpeg'
import img_7 from './../assets/gallery/img-7.jpeg'
import img_8 from './../assets/gallery/img-8.jpeg'
import img_9 from './../assets/gallery/img-9.jpeg'
import img_10 from './../assets/gallery/img-10.jpeg'
import img_11 from './../assets/gallery/img-11.jpeg'
import img_12 from './../assets/gallery/img-12.jpeg'
import img_13 from './../assets/gallery/img-13.jpeg'
import img_14 from './../assets/gallery/img-14.jpeg'
import img_15 from './../assets/gallery/img-15.jpeg'
import img_16 from './../assets/gallery/img-16.jpeg'
// import img_17 from './../assets/gallery/img-17.jpeg'
// import img_18 from './../assets/gallery/img-18.jpeg'
// import img_19 from './../assets/gallery/img-19.jpeg'


let images = [ 
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
    img_15,
    img_16,
   
    
  ]

export default images