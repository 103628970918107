import React from 'react'
import Header from '../components/header/Header'
import { useLocation } from 'react-router';

const About = () => {

  const { page } = useLocation()
  // console.log(page)
 
  return (
    <div style={{marginTop : '0px'}}>
      <Header />
    </div>
  )
}

export default About